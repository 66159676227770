import React from "react";
import TitleLabel from "../../components/common/TitleLabel/TitleLabel";
import SubtitleLabel from "../../components/common/SubtitleLabel/SubtitleLabel";
import ResetPasswordForm from "./modules/ResetPasswordForm";
import "./ResetPassword.styles.sass";

const Login = () => {

    return (
        <div className="loginFormContainer">
            <div className="formContainer">
                <TitleLabel label={"Reset your Password"} />
                <SubtitleLabel label={"Please, create a new password for your account"} />
                <ResetPasswordForm />
            </div>
        </div>
    );
};

export default Login;
