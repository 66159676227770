import { RootState } from "../rootReducer";

export const isPostSignUpSelector = (state: RootState) => state.authReducer.isPostSignUpLoading;
export const isPostSignInSelector = (state: RootState) => state.authReducer.isPostSignInLoading;
export const isPostSignIsCompletedSelector = (state: RootState) => state.authReducer.isPostSignInCompleted;
export const examplePayloadSelector = (state: RootState) => state.authReducer.examplePayload;
export const loginPayloadSelector = (state: RootState) => state.authReducer.loginResponse;
export const preCreateAccountPayloadSelector = (state: RootState) => state.authReducer.preCreateAccountPayload;
export const getUserSelector = (state: RootState) => state.authReducer.user;
export const isgetUserByIdLoadingSelector = (state: RootState) => state.authReducer.isgetUserByIdLoading;
export const isPatientSignInLoadingSelector = (state: RootState) => state.authReducer.isPatientSignInLoading;
export const isPatientSignInCompletedSelector = (state: RootState) => state.authReducer.isPatientSignInCompleted;
export const patientSelector = (state: RootState) => state.authReducer.patient;
