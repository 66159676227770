import { useEffect } from "react";
import { useLocation } from "react-router-dom";
const Base = () => {
    const location = useLocation();
    useEffect(() => {
        //eslint-disable-next-line
        console.log(location);
    }, [location]);
    if (location.pathname === "/" && location.hash === "")
        window.location.href = `${process.env.REACT_APP_AUTH_APP_URL || "http://localhost:3015"}/#/login`;

    return <></>;
};

export default Base;
