import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import patientsList from "../../api/PatientsList.api";
import { AxiosResponse } from "axios";
import { CEThunkPayload } from "../../api/gateway/carexGateway";
import { IPatientListState } from "./patientsList.types";

const initialState: IPatientListState = {
    isGetPatientsLoading: false,
    patientsPayload: { rows: [], count: 0 },
    isPDFSent: false,
    showGroup: 0,
    highlightCQM: false,
};

const payloadFactory = (payload: CEThunkPayload<any>, service: Promise<any>) => {
    const success = payload.onSuccess ? payload.onSuccess : (res: AxiosResponse) => Promise.resolve(res);
    const error = payload.onError ? payload.onError : (err: AxiosResponse) => Promise.reject(err);
    return service.then(success).catch(error);
};

export const getPatientsThunkAction = createAsyncThunk("patientsSlice/getPatients", (payload: CEThunkPayload<any>) =>
    payloadFactory(payload, patientsList.getPatients(payload.data))
);

const patientsSlice = createSlice({
    name: "patientsSlice",
    initialState,
    reducers: {
        toggleGroup: (state, action) => {
            state.showGroup = action.payload;
        },
        toggleHighlightCQM: (state, action) => {
            state.highlightCQM = action.payload;
        },
        updateNotificationCounterByPatientId: (state, action) => {
            const patientId = action.payload;
            const patient = state.patientsPayload.rows.find((p: any) => p.id === patientId);
            if (patient) {
                patient.notificationCount = patient.notificationCount - 1;
            }
        },
    },
    extraReducers: builder => {
        builder.addCase(getPatientsThunkAction.pending, (state: IPatientListState) => {
            state.isGetPatientsLoading = true;
            state.patientsPayload = { rows: [], count: 0 };
        });
        builder.addCase(getPatientsThunkAction.fulfilled, (state: IPatientListState, { payload }) => {
            state.isGetPatientsLoading = false;
            state.patientsPayload = payload;
        });
        builder.addCase(getPatientsThunkAction.rejected, (state: IPatientListState) => {
            state.isGetPatientsLoading = false;
        });
    },
});

export const { toggleGroup, toggleHighlightCQM, updateNotificationCounterByPatientId } = patientsSlice.actions;
export default patientsSlice.reducer;
