import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CEThunkPayload } from "../../api/gateway/carexGateway";
import { AxiosResponse } from "axios";
import validationServices from "../../api/validation.api";

const initialState = {
    validityStatus: false,
};
const payloadFactory = (payload: CEThunkPayload<any>, service: Promise<any>) => {
    const success = payload.onSuccess ? payload.onSuccess : (res: AxiosResponse) => Promise.resolve(res);
    const error = payload.onError ? payload.onError : (err: AxiosResponse) => Promise.reject(err);
    return service.then(success).catch(error);
};

export const postCheckValidityAsync = createAsyncThunk("validition/postCheckValidity", (payload: CEThunkPayload<any>) =>
    payloadFactory(payload, validationServices.postOTPAndDownloadCCDA(payload.data))
);

const validationSlice = createSlice({
    name: "validation",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(postCheckValidityAsync.pending, state => {
            state.validityStatus = false;
        });
        builder.addCase(postCheckValidityAsync.fulfilled, (state, { payload }) => {
            state.validityStatus = { ...payload };
        });
        builder.addCase(postCheckValidityAsync.rejected, state => {
            state.validityStatus = false;
        });
    },
});

export default validationSlice.reducer;
