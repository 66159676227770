import React from 'react'
import { useTranslation } from 'react-i18next'
import TitleLabel from '../../components/common/TitleLabel/TitleLabel'
import SubtitleLabel from '../../components/common/SubtitleLabel/SubtitleLabel'
import ForgotPasswordForm from './modules/ForgotPasswordForm'
import './ForgotPassword.styles.sass'

const Login = () => {
    const [t]: any = useTranslation("global")

    return (
        <div className="loginFormContainer">
            <div className='formContainer'>
                <TitleLabel label={t('forgot-password.title')} />
                <SubtitleLabel label={t('forgot-password.subtitle')} />
                < ForgotPasswordForm handleSubmit={''} />
            </div>
        </div>
    )
}

export default Login