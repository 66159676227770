// Patient-level Scopes
const PATIENT_SCOPES = [
    "launch/patient",
    "patient/Patient.read",
    "patient/Patient.write",
    "patient/Observation.read",
    "patient/Observation.write",
    "patient/Condition.read",
    "patient/Condition.write",
    "patient/MedicationRequest.read",
    "patient/MedicationRequest.write",
    "patient/MedicationStatement.read",
    "patient/MedicationStatement.write",
    "patient/Procedure.read",
    "patient/Procedure.write",
    "patient/AllergyIntolerance.read",
    "patient/AllergyIntolerance.write",
    "patient/CarePlan.read",
    "patient/CarePlan.write",
    "patient/DiagnosticReport.read",
    "patient/DiagnosticReport.write",
    "patient/Encounter.read",
    "patient/Encounter.write",
    "patient/Immunization.read",
    "patient/Immunization.write",
];
// User-level Scopes
const USER_SCOPES = [
    "user/Patient.read",
    "user/Patient.write",
    "user/Observation.read",
    "user/Observation.write",
    "user/Condition.read",
    "user/Condition.write",
    "user/MedicationRequest.read",
    "user/MedicationRequest.write",
    "user/MedicationStatement.read",
    "user/MedicationStatement.write",
    "user/Procedure.read",
    "user/Procedure.write",
    "user/AllergyIntolerance.read",
    "user/AllergyIntolerance.write",
    "user/CarePlan.read",
    "user/CarePlan.write",
    "user/DiagnosticReport.read",
    "user/DiagnosticReport.write",
    "user/Encounter.read",
    "user/Encounter.write",
    "user/Immunization.read",
    "user/Immunization.write",
];
// System-level Scopes
const SYSTEM_SCOPES = [
    "system/Patient.read",
    "system/Patient.write",
    "system/Observation.read",
    "system/Observation.write",
    "system/Condition.read",
    "system/Condition.write",
    "system/MedicationRequest.read",
    "system/MedicationRequest.write",
    "system/MedicationStatement.read",
    "system/MedicationStatement.write",
    "system/Procedure.read",
    "system/Procedure.write",
    "system/AllergyIntolerance.read",
    "system/AllergyIntolerance.write",
    "system/CarePlan.read",
    "system/CarePlan.write",
    "system/DiagnosticReport.read",
    "system/DiagnosticReport.write",
    "system/Encounter.read",
    "system/Encounter.write",
    "system/Immunization.read",
    "system/Immunization.write",
];
// OpenID Scopes
const OPENID_SCOPES = ["openid", "profile", "offline_access"];

// EXPORTS
export const SCOPES = {
    PATIENT: PATIENT_SCOPES,
    USER: USER_SCOPES,
    SYSTEM: SYSTEM_SCOPES,
    OPENID: OPENID_SCOPES,
};
