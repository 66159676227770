import React, { ReactNode } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
interface Auth0ProviderWithHistoryProps {
  children: ReactNode;
}

const Auth0ProviderWithHistory: React.FC<Auth0ProviderWithHistoryProps> = ({children}) => {
  /* const domain = 'dev-jnztz4tqhc2opm7t.us.auth0.com';
  const clientId = 'COEx4JUJzT6lYhOyQw7w5HCgFGLGARn8';
  const audience = 'https://dev-jnztz4tqhc2opm7t.us.auth0.com/userinfo'; // Replace with your API identifier if you have one
 */
  const domain = 'dev-jnztz4tqhc2opm7t.us.auth0.com';
  const clientId = 'MtWXn7z5C7BNHgSCVaTZJ4XVbGyVLlVS';
  const audience = 'https://dev-jnztz4tqhc2opm7t.us.auth0.com/userinfo'; // Replace with your API identifier if you have one

  
  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.href,
        scope: 'openid profile email',
        audience: audience,
      }}
       useRefreshTokens={true}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;