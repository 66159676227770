import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import TitleLabel from "../../components/common/TitleLabel/TitleLabel";
import SubtitleLabel from "../../components/common/SubtitleLabel/SubtitleLabel";
import LoginForm from "./modules/LoginForm";
import "./Login.styles.sass";
const Login = () => {
    useEffect(() => {
        const agreements = localStorage.getItem("missingAgreements")?.split(",");
        if (!agreements || agreements.length <= 0) localStorage.removeItem("Token");
    }, []);

    const [t]: any = useTranslation("global");

    return (
        <div className="loginFormContainer">
            <div className="formContainer">
                <TitleLabel label={t("login.title")} />
                <div>
                    <SubtitleLabel label={t("login.subtitle")} />
                    <a className="goToRegisterLink" href="/#/register">
                    here
                    </a>
                </div>
                <LoginForm handleSubmit={""} />
            </div>
        </div>
    );
};

export default Login;
