import { doPost } from "./gateway/carexGateway";
import { CancelToken } from "axios";
const validationServices = {
    postOTPAndDownloadCCDA: async (payload: any, cancelToken?: CancelToken) => {
        try {
            const res = await doPost<any, any>("downloadCCDA", {
                cancelToken,
                data: payload,
            });
            return Promise.resolve(res.data);
        } catch (err) {
            return Promise.reject(err);
        }
    },
};

export default validationServices;
