import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import i18next from "i18next";
import global_es from "./locales/es/global.json";
import global_en from "./locales/en/global.json";
import { I18nextProvider } from "react-i18next";
import store from "./redux/index";
import { Provider } from "react-redux";
import "./index.css";
/* i18n Configuration */
i18next.init({
    interpolation: { escapeValue: false },
    lng: "en",
    resources: {
        es: {
            global: global_es,
        },
        en: {
            global: global_en,
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <I18nextProvider i18n={i18next}>
                <App />
            </I18nextProvider>
        </Provider>
    </React.StrictMode>
);
