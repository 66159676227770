import axios from "axios";

export function httpErrorHandler(
    error: any,
    setInvalid?: (value: boolean) => void,
    setOtpError?: (value: string) => void
) {
    if (error === null) throw new Error("Unrecoverable error!! Error is null");
    if (axios.isAxiosError(error)) {
        const response = error?.response;
        const request = error?.request;
        // const config = error?.config;

        if (error.code === "ERR_NETWORK") {
            //eslint-disable-next-line
            console.log("connection problems...");
        } else if (error.code === "ERR_CANCELED") {
            //eslint-disable-next-line
            console.log("connection canceled...");
        }

        if (response) {
            const statusCode = response?.status;
            if (statusCode === 404) {
                //eslint-disable-next-line
                console.log("The requested resource does not exist or has been deleted");
            } else if (statusCode === 400) {
                if (response?.data === "Invalid OTP") {
                    setOtpError && setOtpError(response?.data);
                    return;
                } else if (response?.data === "Link is expired. Please request a new one") {
                    setOtpError && setOtpError(response?.data);
                } else if (response?.data === "Link is already used") {
                    setOtpError && setOtpError(response?.data);
                }
                setInvalid && setInvalid(true);
            }
        } else if (request) {
            // The request was made but no response was recevied
            // can notify user to try again later
        }
    }
    //Something happen in setting up the request and triggered an error
    //eslint-disable-next-line
    console.log(error?.message);
}
