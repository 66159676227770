import { doGet } from "./gateway/carexGateway";
import { CancelToken } from "axios";

const patientsListService = {
    getPatients: async (payload: any, cancelToken?: CancelToken) => {
        try {
            const res = await doGet<any[]>(
                "getPatients",
                {
                    params: {
                        ":offset": payload.offset || 0,
                        ":search_order": payload.search_order || "",
                        ":search_by": payload.search_by || "",
                        ":email": payload.email || "",
                        ":name": payload.name || "",
                        ":id": payload.id || "",
                        ":group": payload.group ? "1" : "0",
                        ":doctorId": payload.searchedPatientsDoctor || undefined,
                        ":months": payload.months || "",
                        ":limit": payload.limit || "",
                        ":withDates": payload.withDates || "",
                        ":emergency": payload.emergency || false,
                    },
                },
                cancelToken
            );
            return Promise.resolve(res.data);
        } catch (err) {
            return Promise.reject(err);
        }
    },
};

export default patientsListService;
