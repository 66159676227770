import React, { useEffect, useState } from "react";
import "./ThirdPartyAuthScopes.styles.scss";

import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";

import { addNewThirdPartyAuthDataThunkAction } from "../../../redux/thirdPArtyAuth/thirdPartyAuth.slice";

import { SCOPES } from "../variables/constants";
import { useSearchParams } from "react-router-dom";

interface ThirdPartyAuthScopesProps {
    isPatient: boolean;
    onScopesChange: (scopes: string[]) => void;
    tableSelectedPatients: number[];
}

const ThirdPartyAuthScopes: React.FC<ThirdPartyAuthScopesProps> = ({
    isPatient,
    onScopesChange,
    tableSelectedPatients,
}) => {
    const [searchParams] = useSearchParams();
    const redirect_uri = searchParams.get("redirect_uri");
    const client_id = searchParams.get("client_id");
    
    const [selectedScopes, setSelectedScopes] = useState<string[]>([]);

    const dispatch = useDispatch<AppDispatch>();

    const allScopes = isPatient
        ? [...SCOPES.OPENID, ...SCOPES.PATIENT]
        : [...SCOPES.OPENID, ...SCOPES.PATIENT, ...SCOPES.USER];
    

    useEffect(() => {
        const availableScopes = isPatient
        ? [...SCOPES.OPENID, ...SCOPES.PATIENT]
        : [...SCOPES.OPENID, ...SCOPES.PATIENT, ...SCOPES.USER];
        setSelectedScopes(availableScopes);
    }, [isPatient]);

    useEffect(() => {
        onScopesChange(selectedScopes);
    }, [selectedScopes, onScopesChange]);

    const handleScopeChange = (scope: string) => {
        setSelectedScopes(prevScopes => {
            if (prevScopes.includes(scope)) {
                return prevScopes.filter(s => s !== scope);
            } else {
                return [...prevScopes, scope];
            }
        });
    };
    const handleAuthorize = () => {
        try {
            (async () => {
                //eslint-disable-next-line
                console.log("Authorizing scopes:");
                //eslint-disable-next-line
                console.log(selectedScopes);
                //eslint-disable-next-line
                console.log("tableSelectedPatients:");
                //eslint-disable-next-line
                console.log(tableSelectedPatients);
                const data = {
                        scopes: selectedScopes,
                        patients: tableSelectedPatients,
                        redirect_uri,
                        client_id
                    }
                dispatch(
                    addNewThirdPartyAuthDataThunkAction(data)
                );
            })();
        } catch (error) {
            //eslint-disable-next-line
            console.error(error);
        }
    };

    return (
        <div className="third-party-auth-scopes">
            <h3 className="third-party-auth-scopes__header">Scopes</h3>
            <ul className="third-party-auth-scopes__list">
                {allScopes.map((scope, index) => (
                    <li key={index} className="third-party-auth-scopes__item">
                        <label className="third-party-auth-scopes__label">
                            <input
                                type="checkbox"
                                className="third-party-auth-scopes__checkbox"
                                checked={selectedScopes.includes(scope)}
                                onChange={() => handleScopeChange(scope)}
                            />
                            <span className="third-party-auth-scopes__scope-text">{scope}</span>
                        </label>
                    </li>
                ))}
            </ul>
            <button className="third-party-auth-scopes__button" onClick={handleAuthorize}>
                Authorize
            </button>
        </div>
    );
};

export default ThirdPartyAuthScopes;
