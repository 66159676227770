import React, { useEffect, useState, useCallback } from "react";
import "./thirdPartyAuthTable.styles.scss";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { patientListDataSelector } from "../../../redux/patientsList/patientsList.selectors";
import { getPatientsThunkAction } from "../../../redux/patientsList/patientsList.slice";
import debounce from "lodash/debounce";

interface IPatient {
    id: number;
    name: string;
    lastName: string;
}

interface ThirdPartyAuthTableProps {
    setFinishedSelectingPatientsTable: (finished: boolean) => void;
    setTableSelectedPatients: (patients: number[]) => void;
}

type ISelectedPatients = number[];

const ThirdPartyAuthPatientsTable: React.FC<ThirdPartyAuthTableProps> = ({
    setFinishedSelectingPatientsTable,
    setTableSelectedPatients,
}) => {
    const dispatch = useDispatch<AppDispatch>();
    const patientsList = useSelector(patientListDataSelector);
    const [selectedPatients, setSelectedPatients] = useState<ISelectedPatients>([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [sortColumn, setSortColumn] = useState("");
    const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
    const [isLoading, setIsLoading] = useState(false);

    const debouncedSearch = useCallback(
        debounce((query: string) => {
            setIsLoading(true);
            dispatch(
                getPatientsThunkAction({
                    data: {
                        offset: 0,
                        limit: 10,
                        search_by: sortColumn,
                        search_order: sortOrder,
                        name: query,
                    },
                })
            ).finally(() => setIsLoading(false));
        }, 1000),
        [sortColumn, sortOrder]
    );

    useEffect(() => {
        setIsLoading(true);
        dispatch(
            getPatientsThunkAction({
                data: {
                    offset: 0,
                    limit: 10,
                    search_by: "",
                    search_order: "",
                    name: "",
                },
            })
        ).finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        if (searchQuery || sortColumn || sortOrder !== "asc") {
            debouncedSearch(searchQuery);
        }
    }, [searchQuery, sortColumn, sortOrder, debouncedSearch]);

    const handleCheckboxChange = (patientId: number) => {
        setSelectedPatients(prev =>
            prev.includes(patientId) ? prev.filter(id => id !== patientId) : [...prev, patientId]
        );
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    const handleSort = (column: string) => {
        if (sortColumn === column) {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            setSortColumn(column);
            setSortOrder("asc");
        }
    };

    const handleFinish = () => {
        setFinishedSelectingPatientsTable(true);
        setTableSelectedPatients(selectedPatients);
    };

    const sortedPatients = [...patientsList.rows].sort((a, b) => {
        if (sortColumn === "name") {
            return sortOrder === "asc" ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
        } else if (sortColumn === "lastName") {
            return sortOrder === "asc" ? a.lastName.localeCompare(b.lastName) : b.lastName.localeCompare(a.lastName);
        }
        return 0;
    });

    return (
        <div className="third-party-auth-table">
            <h2 className="third-party-auth-table__header">Third Party Auth Patients Table</h2>
            <div className="third-party-auth-table__search">
                <input
                    type="text"
                    placeholder="Search by name or last name"
                    value={searchQuery}
                    onChange={handleSearch}
                    className="third-party-auth-table__search-input"
                />
            </div>
            <table className="third-party-auth-table__table">
                <thead>
                    <tr>
                        <th
                            onClick={() => handleSort("name")}
                            className="third-party-auth-table__th third-party-auth-table__name-column"
                        >
                            Name
                        </th>
                        <th
                            onClick={() => handleSort("lastName")}
                            className="third-party-auth-table__th third-party-auth-table__lastname-column"
                        >
                            Last Name
                        </th>
                        <th className="third-party-auth-table__th third-party-auth-table__select-column">Select</th>
                    </tr>
                </thead>
                <tbody>
                    {isLoading ? (
                        <tr>
                            <td
                                colSpan={3}
                                className="third-party-auth-table__message third-party-auth-table__message--loading"
                            >
                                Loading...
                            </td>
                        </tr>
                    ) : sortedPatients.length === 0 ? (
                        <tr>
                            <td
                                colSpan={3}
                                className="third-party-auth-table__message third-party-auth-table__message--no-results"
                            >
                                No results found
                            </td>
                        </tr>
                    ) : (
                        sortedPatients.map((patient: IPatient) => (
                            <tr key={patient.id} className="third-party-auth-table__tr">
                                <td className="third-party-auth-table__td third-party-auth-table__name-column">
                                    {patient.name}
                                </td>
                                <td className="third-party-auth-table__td third-party-auth-table__lastname-column">
                                    {patient.lastName}
                                </td>
                                <td className="third-party-auth-table__td third-party-auth-table__select-column">
                                    <input
                                        type="checkbox"
                                        checked={selectedPatients.includes(patient.id)}
                                        onChange={() => handleCheckboxChange(patient.id)}
                                        className="third-party-auth-table__checkbox"
                                    />
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
            <p className="third-party-auth-table__selected-patients">
                Selected patients: {selectedPatients.join(", ")}
            </p>
            <button onClick={handleFinish} className="third-party-auth-table__button">
                {selectedPatients.length > 1 ? "Select Patients" : "Select Patient"}
            </button>
        </div>
    );
};

export default ThirdPartyAuthPatientsTable;
