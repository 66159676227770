/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import "./RegisterForm.styles.sass";
import { Link, useLocation, useParams } from "react-router-dom";
import PasswordStrengthChecker from "../../../components/common/PasswordStrengthChecker/PasswordStrengthChecker";
import { useTranslation } from "react-i18next";
import { postPreCreateAccountThunkAction, postSignUpThunkAction, preCreateAccountThunkAction } from "../../../redux/auth/auth.slice";
import { useAppDispatch } from "../../../redux/hooks";

import { isPostSignUpSelector, preCreateAccountPayloadSelector } from "../../../redux/auth/auth.selectors";
import { useSelector } from "react-redux";
import Spiner from "../../../components/common/Spiner/Spiner";
import { CEXModal } from "@careexpand/cex-core-components/esm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PhoneInput from "@careexpand/react-phone-input-2";
import { faPhone, faEnvelope, faEyeSlash, faEye } from '@fortawesome/pro-light-svg-icons';


const RegisterForm = () => {
    const [, setStatus] = useState<string>();
    const [, setIsSubmitting] = useState<boolean>();
    const signUpSelector = useSelector(isPostSignUpSelector);

    const [t]: any = useTranslation("global");
    const dispatch = useAppDispatch();
    const [registerError, setRegisterError] = useState<boolean>(false);
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [passwordInput, setPasswordInput] = useState();
    const [errorMsg, setErrorMsg] = useState<any>();
    const [acceptanceChecked, setAcceptanceChecked] = useState<boolean>(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isRepeatPasswordVisible, setIsRepeatPasswordVisible] = useState(false);
    const [passwordFieldError, setPasswordFieldError] = useState(false);
    const [repeatPasswordFieldError, setRepeatPasswordFieldError] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    const pregisterData: any = useSelector(preCreateAccountPayloadSelector);
    useEffect(() => {
        if (token) {
            dispatch(preCreateAccountThunkAction({ data: { token } }));
        }
    }, []);


    const closeModal = () => {
        setRegisterError(false);
    };

    const handleAcceptanceClick = () => {
        setAcceptanceChecked(!acceptanceChecked);
    };
    const checkPasswordStrength = (password: any) => {
        const strongPassword = new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})");
        const mediumPassword = new RegExp(
            "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))"
        );
        if (password === "") setPasswordStrength(0);
        if (strongPassword.test(password)) {
            setPasswordStrength(3);
        } else if (mediumPassword.test(password)) {
            setPasswordStrength(2);
        } else {
            setPasswordStrength(1);
        }
    };

    const handleOnSubmit = async (values: any) => {
        setIsSubmitting(false);
        setStatus("status");
        if (!token) {
            await dispatch(
                postSignUpThunkAction({
                    data: {
                        email: values.email,
                        firstName: values.name,
                        lastName: values.lastName,
                        phone: values.phone,
                        timezone: "UTC",
                        language: "en",
                        password: values.password,
                        selectedTier: localStorage.getItem('tier')
                    },
                })
            ).then((res: any) => {
                if (res.error) {
                    setRegisterError(true);
                    setErrorMsg(res.error.message.includes("401") ? "Email already exists" : "Error creating user");
                } else {
                    window.location.assign("/#/login?redirectFrom=register");
                }
            });
        } else {
            await dispatch(
                postPreCreateAccountThunkAction({
                    data: {
                        formEntries: {
                            email: values.email,
                            firstName: values.name,
                            lastName: values.lastName,
                            phone: values.phone,
                            timezone: "UTC",
                            language: "en",
                            password: values.password
                        },
                        token: token,
                    },
                })
            ).then((res: any) => {
                if (res.error) {
                    setRegisterError(true);
                    setErrorMsg(res.error.message.includes("401") ? "Email already exists" : "Error creating user");
                } else {
                    window.location.assign("/#/login?action=email");
                }
            });
        };
    };
    return (
        <div className="userDetailsForm">


            {registerError && (
                <CEXModal handleModal={() => closeModal()} title={"Register error"} subtitle={errorMsg} type={"info"} />
            )}
            {signUpSelector && <Spiner />}
            <Formik
                initialValues={{ email: "", name: "", lastName: "", password: "", repeatpassword: "", phone: "" }}
                // onSubmit={(values, actions) => {
                // setIsSubmitting(true);
                // const isFormFilled = Object.values(values).every(x => x === null || x === "" || x === undefined);
                // if (!acceptanceChecked) {
                //     setRegisterError(true);
                //     setErrorMsg(t("register.terms-policy-error"));
                // } else {
                //     if (isFormFilled) {
                //         setStatus("error");
                //         setRegisterError(true);
                //         setErrorMsg("Check that all the fields are filled correctly");
                //     } else {
                //         handleOnSubmit(values);
                //     }
                // }
                // }}
                onSubmit={() => {
                    return;
                }}
                validationSchema={yup.object().shape({
                    name: yup
                        .string()
                        .required("Please, fill with your first name")
                        .min(2, "Name should be at least 2 characters"),
                    lastName: yup
                        .string()
                        .required("Please, fill with your last name")
                        .min(2, "Last Name should be at least 2 characters"),
                    email:
                        !token ?
                            yup
                                .string()
                                .email("Please enter a valid email address")
                                .required("Please enter a valid email address")
                            : yup.string(),
                    password: yup
                        .string()
                        .required("Please, fill with your password")
                        .min(8, "Please, fill with your password"),
                    repeatpassword: yup
                        .string()
                        .required("Passwords must match")
                        .oneOf([yup.ref("password"), null], "Passwords must match"),
                    phone:
                        !token ?
                            yup
                                .string()
                                .matches(
                                    /^[+#*\(\)\[\]]*([0-9][ ext+-pw#*\(\)\[\]]*){11,45}$/,
                                    "Please, fill with a valid Phone number"
                                )
                                .required("Please, fill with a valid Phone number") : yup.string()
                    //.required("Please, fill with a valid Phone number")
                })}
            >
                {({ errors, touched, submitCount, values, validateForm, handleChange, handleBlur, setFieldValue }: any) => (
                    <Form className="userDetailsForm">
                        <div className="inputFieldDivRegister">
                            <div className="nameLastNameContainer">
                                <div className="pi-input-skel-block">
                                    <div className="pi-input">
                                        <label className="pi-label" htmlFor="">{t("register.first-name")}</label>
                                        <div className="pi-input-skel">
                                            <span className="pi-input-prefix" style={{ padding: '10px', fontSize: '16px' }}>T</span>
                                            <Field name="name" type="text" className="pi-input-ele" placeholder={t("register.first-name")} />
                                        </div>
                                    </div>
                                    <label className="input__error">
                                        {errors.name && (touched.name || submitCount > 0) && errors.name}
                                    </label>
                                </div>
                                <div className="pi-input-skel-block">
                                    <div className="pi-input">
                                        <label className="pi-label" htmlFor="">{t("register.last-name")}</label>
                                        <div className="pi-input-skel">
                                            <span className="pi-input-prefix" style={{ padding: '10px', fontSize: '16px' }}>T</span>
                                            <Field
                                                name="lastName"
                                                type="text"
                                                className="pi-input-ele"
                                                placeholder={t("register.last-name")}
                                            />
                                        </div>
                                    </div>
                                    <label className="input__error">
                                        {errors.lastName && (touched.lastName || submitCount > 0) && errors.lastName}
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="inputFieldDivRegister">
                            <div className="nameLastNameContainer">
                                <div className="pi-input-skel-block">
                                    <div className="pi-input">
                                        <label className="pi-label" htmlFor="">{t("register.phone")}</label>
                                        <div className="pi-input-skel">
                                            <span className="pi-input-prefix" style={{ padding: '8px 8px' }}>
                                                <FontAwesomeIcon icon={faPhone} size="2xl" style={{ width: '17px' }} />
                                            </span>
                                            <PhoneInput
                                                disabled={token ? true : false}
                                                country={'us'}
                                                value={
                                                        pregisterData?.Phone?.replace(' ', '').replace('+', '') || values.phone
                                                }
                                                onChange={(value: string) => {
                                                    handleChange(value);
                                                    setFieldValue('phone', value)
                                                }}
                                                placeholder={t("register.phone-label")}
                                                containerClass="custom-phone-input-container"
                                                inputClass="custom-phone-input"

                                                inputStyle={{
                                                    backgroundColor: '#F6F6F6',
                                                    color: '#707070',
                                                    paddingTop: '10px',
                                                    paddingBottom: '10px',
                                                    paddingLeft: '10px',
                                                    height: '20px',
                                                    borderRadius: '0 4px 4px 0',
                                                    border: 'none',
                                                    fontSize: '12px',
                                                    width: '100%',
                                                    outline: 'none',
                                                    maxWidth: 'calc(100% - 10px)'
                                                }}

                                            />
                                        </div>
                                    </div>
                                    <label className="input__error">
                                        {errors.phone && (touched.phone || submitCount > 0) && errors.phone}
                                    </label>
                                </div>
                                <div className="pi-input-skel-block">
                                    <div className="pi-input">
                                        <label className="pi-label" htmlFor="">{t("register.email")}</label>
                                        <div className="pi-input-skel">
                                            <span className="pi-input-prefix" style={{ padding: '8px 8px' }}>
                                                <FontAwesomeIcon icon={faEnvelope} size="2xl" style={{ width: '17px' }} />
                                            </span>
                                            <Field
                                                name="email"
                                                type="email"
                                                disabled={token ? true : false}
                                                className="pi-input-ele"
                                                placeholder={t("register.email-label")}
                                                value={pregisterData?.Email || values.email}
                                            />
                                        </div>
                                    </div>
                                    <label className="input__error">
                                        {errors.email && (touched.email || submitCount > 0) && errors.email}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="inputFieldDivRegister">
                            <div className="nameLastNameContainer">
                                <div className="pi-input-skel-block">
                                    <div className="pi-input">
                                        <label className="pi-label" htmlFor="">{t("register.password")}</label>
                                        <div className="pi-input-skel">
                                            <span className="pi-input-prefix" style={{ padding: '8px 8px', cursor: 'pointer' }} onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
                                                <FontAwesomeIcon icon={isPasswordVisible ? faEye : faEyeSlash} size="2xl" style={{ width: '17px' }} />
                                            </span>
                                            <Field
                                                name="password"
                                                type={isPasswordVisible ? "text" : "password"}
                                                placeholder={t("register.password-label")}
                                                className="pi-input-ele"
                                                validate={(e: any) => {
                                                    setPasswordInput(e);
                                                    checkPasswordStrength(passwordInput);
                                                    setPasswordFieldError(errors.password && errors.password ? true : false);
                                                }}
                                                onClick={() => {
                                                    setPasswordFieldError(false);
                                                }}
                                                onBlur={handleBlur}
                                            />
                                            <span className="tooltiptext">
                                                You must include at least a capital and small letter, a number an a special character.
                                            </span>
                                        </div>
                                    </div>
                                    <label className="input__error">
                                        {passwordFieldError &&
                                            errors.password &&
                                            (touched.password || submitCount > 0) &&
                                            errors.password}
                                    </label>
                                </div>
                                <div className="pi-input-skel-block">
                                    <div className="pi-input">
                                        <label className="pi-label" htmlFor="">{t("register.repeat-password")}</label>
                                        <div className="pi-input-skel">
                                            <span className="pi-input-prefix" style={{ padding: '8px 8px', cursor: 'pointer' }} onClick={() => setIsRepeatPasswordVisible(!isRepeatPasswordVisible)}>
                                                <FontAwesomeIcon icon={isRepeatPasswordVisible ? faEye : faEyeSlash} size="2xl" style={{ width: '17px' }} />
                                            </span>
                                            <Field
                                                name="repeatpassword"
                                                type={isRepeatPasswordVisible ? "text" : "password"}
                                                placeholder={t("register.repeat-password-label")}
                                                className="pi-input-ele"
                                                onBlur={handleBlur}
                                                validate={() => {
                                                    setRepeatPasswordFieldError(
                                                        errors.repeatpassword && errors.repeatpassword ? true : false
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <label className="input__error">
                                        {repeatPasswordFieldError &&
                                            errors.repeatpassword &&
                                            (touched.repeatpassword || submitCount > 0) &&
                                            errors.repeatpassword}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <PasswordStrengthChecker passwordStrength={passwordStrength} />
                        <div className="privacyPolicyContainer">
                            <div className="commonText">
                                <input
                                    onChange={() => {
                                        handleAcceptanceClick();
                                    }}
                                    required
                                    type="checkbox"
                                    checked={acceptanceChecked}
                                    className="registerFormCheckbox clickable"
                                />
                                <label>
                                    {t("register.accept-terms")}{" "}
                                    <a
                                        href={`https://${process.env.NODE_ENV === ("development" || "production") ? "dev." : ""
                                            }careexpand.com/termsandconditions.html`}
                                        className="linkText"
                                    >
                                        {t("register.terms-policy")}
                                    </a>
                                </label>
                            </div>
                        </div>
                        <div className="reg_btn_container">
                            <div className="backSignIn">
                                <Link to="/login" className="backSignIn" style={{ textDecoration: "none" }}>
                                    {t("register.login-link")}
                                </Link>
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    className="Reg-blueButton"
                                    style={{
                                        fontSize:
                                            window.innerWidth <= 320
                                                ? "11px"
                                                : window.innerWidth === 768
                                                    ? "10px"
                                                    : window.innerWidth >= 426 && window.innerWidth <= 514
                                                        ? "10px"
                                                        : "14px",
                                    }}
                                    onClick={() => {
                                        validateForm();
                                        // eslint-disable-next-line no-console
                                        console.log('Values', values, errors, Object.keys(errors).length === 0, "acceptanceChecked--> ", acceptanceChecked)
                                        const selectedTier = localStorage.getItem('tier')
                                        if (!selectedTier) setErrorMsg('You must choose a tier before creating an account')
                                        if (acceptanceChecked && errors && Object.keys(errors).length === 0 && selectedTier
                                        ) {
                                            handleOnSubmit(values);
                                        }
                                    }}
                                >
                                    {t("register.register-button")}
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default RegisterForm;
