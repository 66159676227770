import React from "react";
import "./SubtitleLabel.styles.sass";
const SubtitleLabel = (props: any) => {
    return (
        <div className="createAccountDiv">
            <div className="createAccountText">{props.label}&nbsp;</div>
        </div>
    );
};

export default SubtitleLabel;
