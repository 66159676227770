import React from "react";
import "./AuthBody.styles.sass";
import newLogoImg from "../../../assets/images/new-logo.svg";
import blueRingImg from "../../../assets/images/blue-ring.png";

const AuthBody: React.FC<any> = () => {
    return (
        <>
            <div className="carexLogoContainer1">
                <img className="carexLogo" src={newLogoImg} alt="Careexpand_logo" />
            </div>
            <div className="loginImageContainer">
                <img className="blue-ring" src={blueRingImg} alt="" />
                <div className="centeredText">
                    <div className="centeredText--main">The Operating System for value-based care</div>
                    <div className="centeredText--secondary">
                        And experience the impact of telemedicine within your organization
                    </div>
                </div>
            </div>
        </>
    );
};
export default AuthBody;
