import "./Rating.styles.sass";

import { ReactComponent as Star } from "../../../assets/icons/star.svg";
import { ReactComponent as HalfStar } from "../../../assets/icons/halfStar.svg";
import { useState } from "react";
interface ratingFunction {
    (item: number): void;
}
interface ratingProps {
    rating: number;
    setRating: ratingFunction;
}
const Rating = (props: ratingProps) => {
    const { rating, setRating } = props;
    const [hoveredRating, setHoveredRating] = useState(0);
    return (
        <div
            className="ratingModuleRatingsContainer"
            onMouseLeave={() => {
                setHoveredRating(0);
            }}
        >
            <div className="ratingModuleRatingContainer">
                <div
                    onMouseEnter={() => {
                        setHoveredRating(1);
                    }}
                >
                    <Star
                        className={"ratingModuleRatingStar"}
                        fill={
                            hoveredRating != 0
                                ? hoveredRating >= 1
                                    ? "#0DB261"
                                    : "#EEEDED"
                                : rating >= 1
                                ? "#0DB261"
                                : "#EEEDED"
                        }
                        width={20}
                        height={20}
                        onClick={() => {
                            setRating(1);
                        }}
                    />
                </div>
                <div
                    onMouseEnter={() => {
                        setHoveredRating(0.5);
                    }}
                >
                    <HalfStar
                        className={"ratingModuleRatingStar"}
                        fill={
                            hoveredRating != 0
                                ? hoveredRating >= 0.5
                                    ? "#0DB261"
                                    : "#EEEDED"
                                : rating >= 0.5
                                ? "#0DB261"
                                : "#EEEDED"
                        }
                        width={10}
                        height={20}
                        onClick={() => {
                            setRating(0.5);
                        }}
                    />
                </div>
            </div>
            <div className="ratingModuleRatingContainer">
                <div
                    onMouseEnter={() => {
                        setHoveredRating(2.5);
                    }}
                >
                    <Star
                        className={"ratingModuleRatingStar"}
                        fill={
                            hoveredRating != 0
                                ? hoveredRating >= 2
                                    ? "#0DB261"
                                    : "#EEEDED"
                                : rating >= 2
                                ? "#0DB261"
                                : "#EEEDED"
                        }
                        width={20}
                        height={20}
                        onClick={() => {
                            setRating(2);
                        }}
                    />
                </div>
                <div
                    onMouseEnter={() => {
                        setHoveredRating(1.5);
                    }}
                >
                    <HalfStar
                        className={"ratingModuleRatingStar"}
                        fill={
                            hoveredRating != 0
                                ? hoveredRating >= 1.5
                                    ? "#0DB261"
                                    : "#EEEDED"
                                : rating >= 1.5
                                ? "#0DB261"
                                : "#EEEDED"
                        }
                        width={10}
                        height={20}
                        onClick={() => {
                            setRating(1.5);
                        }}
                    />
                </div>
            </div>
            <div className="ratingModuleRatingContainer">
                <div
                    onMouseEnter={() => {
                        setHoveredRating(3);
                    }}
                >
                    <Star
                        className={"ratingModuleRatingStar"}
                        fill={
                            hoveredRating != 0
                                ? hoveredRating >= 3
                                    ? "#0DB261"
                                    : "#EEEDED"
                                : rating >= 3
                                ? "#0DB261"
                                : "#EEEDED"
                        }
                        width={20}
                        height={20}
                        onClick={() => {
                            setRating(3);
                        }}
                    />
                </div>
                <div
                    onMouseEnter={() => {
                        setHoveredRating(2.5);
                    }}
                >
                    <HalfStar
                        className={"ratingModuleRatingStar"}
                        fill={
                            hoveredRating != 0
                                ? hoveredRating >= 2.5
                                    ? "#0DB261"
                                    : "#EEEDED"
                                : rating >= 2.5
                                ? "#0DB261"
                                : "#EEEDED"
                        }
                        width={10}
                        height={20}
                        onClick={() => {
                            setRating(2.5);
                        }}
                    />
                </div>
            </div>
            <div className="ratingModuleRatingContainer">
                <div
                    style={{ position: "absolute" }}
                    onMouseEnter={() => {
                        setHoveredRating(4);
                    }}
                >
                    <Star
                        className={"ratingModuleRatingStar"}
                        fill={
                            hoveredRating != 0
                                ? hoveredRating >= 4
                                    ? "#0DB261"
                                    : "#EEEDED"
                                : rating >= 4
                                ? "#0DB261"
                                : "#EEEDED"
                        }
                        width={20}
                        height={20}
                        onClick={() => {
                            setRating(4);
                        }}
                    />
                </div>
                <div
                    style={{ position: "absolute" }}
                    onMouseEnter={() => {
                        setHoveredRating(3.5);
                    }}
                >
                    <HalfStar
                        className={"ratingModuleRatingStar"}
                        fill={
                            hoveredRating != 0
                                ? hoveredRating >= 3.5
                                    ? "#0DB261"
                                    : "#EEEDED"
                                : rating >= 3.5
                                ? "#0DB261"
                                : "#EEEDED"
                        }
                        width={10}
                        height={20}
                        onClick={() => {
                            setRating(3.5);
                        }}
                    />
                </div>
            </div>
            <div className="ratingModuleRatingContainer">
                <div
                    style={{ position: "absolute" }}
                    onMouseEnter={() => {
                        setHoveredRating(5);
                    }}
                >
                    <Star
                        className={"ratingModuleRatingStar"}
                        fill={
                            hoveredRating != 0
                                ? hoveredRating >= 5
                                    ? "#0DB261"
                                    : "#EEEDED"
                                : rating >= 5
                                ? "#0DB261"
                                : "#EEEDED"
                        }
                        width={20}
                        height={20}
                        onClick={() => {
                            setRating(5);
                        }}
                    />
                </div>
                <div
                    onMouseEnter={() => {
                        setHoveredRating(4.5);
                    }}
                >
                    <HalfStar
                        className={"ratingModuleRatingStar"}
                        fill={
                            hoveredRating != 0
                                ? hoveredRating >= 4.5
                                    ? "#0DB261"
                                    : "#EEEDED"
                                : rating >= 4.5
                                ? "#0DB261"
                                : "#EEEDED"
                        }
                        width={10}
                        height={20}
                        onClick={() => {
                            setRating(4.5);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default Rating;
