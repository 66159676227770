import { useEffect, useState } from "react";
import "./OTPValidation.styles.sass";
import carexLogo from "../../assets/images/Careexpand-Logo-no-padding.png";

import { httpErrorHandler } from "./utils";

//TODO just call the api directly, redux for this use case is overkill'
// api
import validationServices from "../../api/validation.api";
import { useLocation } from "react-router-dom";
// Component
import Spiner from "../../components/common/Spiner/Spiner";
import AuthBody from "../../components/common/AuthBody/AuthBody";
import ValidationForm from "./ValidationForm";

const OTPValidation = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);
    const [otpError, setOtpError] = useState("");

    const id = searchParams.get("id");
    const signature = searchParams.get("signature");

    useEffect(() => {
        // Check link validity on mount
        const checkValidity = async () => {
            setIsProcessing(true);
            setIsInvalid(false);
            try {
                const payload = {
                    id: id,
                    signature: signature,
                };
                await validationServices.postOTPAndDownloadCCDA(payload);
            } catch (error) {
                httpErrorHandler(error, setIsInvalid, setOtpError);
            } finally {
                setIsProcessing(false);
            }
        };
        if (id && signature) {
            checkValidity();
        } else {
            setIsInvalid(true);
        }
    }, []);

    return isProcessing ? (
        <Spiner />
    ) : (
        <div className="loginBodyContainer">
            <div className="carexLogoContainer0">
                <img className="carexLogo" src={carexLogo} alt="Careexpand_logo" />
                <div className="carexLogoText">The Operating System for Virtual Medicine</div>
            </div>
            <div className="leftSideDiv">
                <AuthBody />
            </div>
            <div className="rightSideDiv">
                {isInvalid ? (
                    <div className="validationInvalid">
                        {otpError && otpError ? <h2>Link Expired!</h2> : <h2>Opps!</h2>}
                        <p className="validationInvalidText">
                            {otpError && otpError
                                ? otpError
                                : "Hello, it looks like this link has expired/or already used."}
                        </p>
                    </div>
                ) : (
                    <ValidationForm numberOfDigit={6} id={id} signature={signature} />
                )}
            </div>
        </div>
    );
};
export default OTPValidation;
