import React, { useEffect, useState } from 'react'
import './TierPicker.styles.scss'

type Tier = {
    title: string;
    price: number;
    featuresKey: string;
    features: string[]
};

type AppTiers = {
    telemedicine: Tier;
    continuityOfCare: Tier;
    fullPracticeManagement: Tier;
};
const TierPicker = () => {
    const [selectedTier, setSelectedTier] = useState(3);
    const [isAnimating, setIsAnimating] = useState(false);
    const [openModal, setOpenModal] = useState(true)
    const handleSelectedTier = (tier: number) => {
        setSelectedTier(tier)
        localStorage.setItem('tier', tier.toString())
    }
    const APP_TIERS: AppTiers = {
        telemedicine: {
            title: 'Telemedicine',
            price: 49,
            featuresKey: 'telemedicine',
            features: [
                "Multichannel Communication",
                "Virtual Waiting Room",
                "Meeting Notes with AI Codes",
                "AI enhanced ePrescribing",
                "Clinician Workflow",
            ]
        },
        continuityOfCare: {
            title: 'Continuity of Care',
            price: 149,
            featuresKey: 'telemedicine',
            features: [
                'Care Coordination',
                'Remote Patient Monitoring',
                'Patient Messaging',
                'Chronic Disease Management',
                'Referral Management',
                'Automated Care Plans',
                'Integrated Billing and Payment Solutions'
            ],
        },
        fullPracticeManagement: {
            title: 'Full Practice Management',
            price: 249,
            featuresKey: 'telemedicine',
            features: [
                'Continuity of Care Plan',
                'Automated Coding with AI-Generated Data tags',
                'Customizable Charting Templates',
                'Clinical Decision Support',
                'Clinical Quality Measures',
                'Integrated Lab Results',
                'Advanced Charting Capabilities',
                'Interoperability with Other Systems',
                'Patient Portal Access'
            ]
        }
    };

    useEffect(() => {
        if (openModal) {
            setIsAnimating(true);
        } else {
            setIsAnimating(false);
        }
    }, [openModal]);
    return (
        <>
            {openModal &&
                <div className={`tier-picker__backdrop ${isAnimating ? 'show' : ''}`}>
                    <div className='tier-picker__container'>
                        <div className='tier-picker__container__title'>Choose the plan that fit your needs</div>
                        <TierCard
                            title={APP_TIERS.telemedicine.title}
                            price={APP_TIERS.telemedicine.price}
                            features={APP_TIERS.telemedicine.features}
                            selected={selectedTier === 1}
                            onSelected={() => handleSelectedTier(1)}
                        />
                        <TierCard
                            title={APP_TIERS.continuityOfCare.title}
                            price={APP_TIERS.continuityOfCare.price}
                            features={APP_TIERS.continuityOfCare.features}
                            selected={selectedTier === 2}
                            onSelected={() => { handleSelectedTier(2) }}
                        />
                        <TierCard
                            title={APP_TIERS.fullPracticeManagement.title}
                            price={APP_TIERS.fullPracticeManagement.price}
                            features={APP_TIERS.fullPracticeManagement.features}
                            selected={selectedTier === 3}
                            onSelected={() => { handleSelectedTier(3) }}
                        />
                    </div>
                    <div className='continueButton' onClick={() => { setOpenModal(false) }}> Continue</div >
                </div >
            }
        </>
    )
}

export default TierPicker
type TierCardProps = {
    title: string;
    price: number;
    features: string[];
    selected: boolean;
    onSelected: () => void
}
const TierCard = (props: TierCardProps) => {
    const { title, price, features, selected, onSelected } = props
    return (
        <div className={`tier-picker__tier-card ${selected && 'tier-card__selected'}`} onClick={onSelected}>
            <div className="tier-card__title">{title}</div>
            <div className="tier-card__price">{`$${price}/mo`}</div>
            <div className="tier-card__features">
                <div className="features__header"></div>
                <ul className="features__list">
                    <li className=''>{features[0]}</li>
                    {
                        features.slice(1).map((feature: any) => {
                            return (<li>{feature}</li>)
                        })
                    }
                </ul>
            </div>

        </div>
    )
}

