import React, {  useState } from "react";
import "./ThirdPartyAuthMain.styles.scss";

import ThirdPartyAuthPatientsTable from "../Components/ThirdPartyAuthTable";
import ThirdPartyAuthScopes from "../Components/ThirdPartyAuthScopes";

interface ThirdPartyAuthMainProps {
    isPatient: boolean;
}

const ThirdPartyAuthMain: React.FC<ThirdPartyAuthMainProps> = ({ isPatient }) => {
    const [finishedSelectingPatientsTable, setFinishedSelectingPatientsTable] = useState(false);
    const [tableSelectedPatients, setTableSelectedPatients] = useState<number[]>([]);
    const [, setSelectedScopes] = useState<string[]>([]);

    

    return (
        <div className="third-party-auth-main">
            <h1 className="third-party-auth-main__title">Welcome to Third Party Authorization</h1>
            <p className="third-party-auth-main__description">
                In this portal, you can authorize and manage sharing your information with third parties such as other
                EHRs or EMRs.
            </p>
            {isPatient && <p className="third-party-auth-main__user-type">You are a patient</p>}

            {!isPatient && <p className="third-party-auth-main__user-type">You are a provider</p>}

            {!isPatient && !finishedSelectingPatientsTable && (
                <ThirdPartyAuthPatientsTable
                    setFinishedSelectingPatientsTable={setFinishedSelectingPatientsTable}
                    setTableSelectedPatients={setTableSelectedPatients}
                />
            )}

            {(!isPatient && finishedSelectingPatientsTable) || isPatient ? (
                <ThirdPartyAuthScopes
                    isPatient={isPatient}
                    onScopesChange={setSelectedScopes}
                    tableSelectedPatients={tableSelectedPatients}
                />
            ) : null}
        </div>
    );
};

export default ThirdPartyAuthMain;
