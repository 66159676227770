import React from 'react'
import { useTranslation } from 'react-i18next'
import './PasswordStrengthChecker.styles.sass'
const PasswordStrengthChecker = (props:any) => {

    const [t]: any = useTranslation("global")
   
    return (

        <div className="passwordStrengthChecker">
            <div className="commonText">
                {t('register.password-strength')}
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <div className={props.passwordStrength <= 1 ? "passwordStrengthMeterRed" : "passwordStrengthMeterGreen"}></div>
                <div className={props.passwordStrength < 1 ? "passwordStrengthMeterGray" : props.passwordStrength < 2 ? "passwordStrengthMeterRed" : "passwordStrengthMeterGreen"}></div>
                <div className={props.passwordStrength < 2 ? "passwordStrengthMeterGray" : "passwordStrengthMeterGreen"}></div>
                <div className={props.passwordStrength !== 3 ? "passwordStrengthMeterGray" : "passwordStrengthMeterGreen"}></div>
            </div>
        </div>

    )
}

export default PasswordStrengthChecker