import { QueryParamsResolver, resolveUrl, ServiceNames } from "./gatewayEndpoints";
import axios, { AxiosError, AxiosResponse, CancelToken } from "axios";
export const baseAxiosInstance = axios.create({
    baseURL: "",
    // baseURL: `${
    //   process.env.REACT_APP_API_SERVER_URL
    //     ? process.env.REACT_APP_API_SERVER_URL
    //     : window.clientInfo
    //     ? window.clientInfo.api
    //     : window.location.origin
    // }`,
});

baseAxiosInstance.interceptors.request.use(function (config: any) {
    const token = localStorage.getItem("Token") || null;
    if (token) {
        config.headers.Authorization = "Bearer " + token;
    }
    return config;
});

export const updateApiToken = (token: string) => {
    baseAxiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const handleError = (error: AxiosError) => {
    if (error.config && error.response && error.response.status === 401) {
        // localStorage.clear();
    }
    return Promise.reject(error);
};

baseAxiosInstance.interceptors.response.use(undefined, handleError);

export const getCancelRequestToken = () => axios.CancelToken.source();

export interface WithBodyData<T> extends QueryParamsResolver {
    data: T;
    cancelToken?: CancelToken;
}

export const doGet = <T>(serviceName: ServiceNames, config: QueryParamsResolver, cancelToken?: CancelToken) =>
    baseAxiosInstance.get<T>(resolveUrl(serviceName, config), {
        cancelToken,
        headers: config.headers,
        responseType: config.responseType,
    });

export const doPost = <T, BodyPayload>(serviceName: ServiceNames, config: WithBodyData<BodyPayload>) =>
    baseAxiosInstance.post<T>(resolveUrl(serviceName, config), config.data, {
        cancelToken: config.cancelToken,
        headers: config.headers,
    });

export const doPut = <T, BodyPayload>(serviceName: ServiceNames, config: WithBodyData<BodyPayload>) =>
    baseAxiosInstance.put<T>(resolveUrl(serviceName, config), config.data, {
        cancelToken: config.cancelToken,
    });

export const doPatch = async <T, BodyPayload>(serviceName: ServiceNames, config: WithBodyData<BodyPayload>) =>
    baseAxiosInstance.patch<T>(resolveUrl(serviceName, config), config.data, {
        cancelToken: config.cancelToken,
        headers: config.headers,
    });

export const doDelete = (serviceName: ServiceNames, config: QueryParamsResolver, cancelToken?: CancelToken) =>
    baseAxiosInstance.delete(resolveUrl(serviceName, config), {
        cancelToken,
    });

export interface CEThunkPayload<T> {
    data: T;
    onSuccess?: (res: AxiosResponse) => Promise<any>;
    onError?: (err: Error) => Promise<never>;
}
