import { useEffect, useState } from "react";
import "./ConnectYourAccounts.styles.sass";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import carexLogo from "../../assets/images/Careexpand-Logo-no-padding.png";
// You can use provided image shipped by this package or using your own
import { createPostAccountThunkAction } from "../../redux/posts/posts.slice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import linkedin from "react-linkedin-login-oauth2/assets/linkedin.png";
import { useSearchParams } from "react-router-dom";
import { decodeJWTToken } from "../../lib/jwtLib";
import Spiner from "../../components/common/Spiner/Spiner";
import AuthBody from "../../components/common/AuthBody/AuthBody";

const SOCIAL_PLATFORMS: any = {
    LINKEDIN: "LINKEDIN",
    META: "META",
    TWITTER: "TWITTER",
    INSTAGRAM: "INSTAGRAM",
};

const ConnectYourAccounts = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [searchParams] = useSearchParams();
    const [isProcessing, setIsProcessing] = useState(false);

    const { linkedInLogin } = useLinkedIn({
        clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID || "8687n5x0xsnbe9",
        redirectUri: `${window.location.origin + window.location.pathname}`,
        scope: "r_emailaddress r_liteprofile w_member_social",
        onSuccess: code => {
            //eslint-disable-next-line
            console.log(code);
            checkCode();
        },
        onError: error => {
            //eslint-disable-next-line
            console.log(error);
            checkCode();
        },
    });

    useEffect(() => {
        checkCode();
    }, []);

    const checkCode = () => {
        const code = searchParams.get("code");
        if (code && code.length > 200) {
            handleUpdateAccount(SOCIAL_PLATFORMS.LINKEDIN, { token: code });
        }
    };

    const handleUpdateAccount = (appId: string, data: any) => {
        const userToken = localStorage.getItem("Token") || "";
        const token: any = userToken
            ? decodeJWTToken(userToken)
            : { email: "user@careexpand.com", name: "User", lastName: "Name" };
        const payload = {
            ...data,
            appId,
            url: "http://linkedin.com",
            enabled: true,
            email: token.email || "",
            username: (token.name + token.lastName).trim().replace(/ /g, "") || "",
        };
        setIsProcessing(true);
        dispatch(
            createPostAccountThunkAction({
                data: payload,
            })
        ).then(res => {
            //eslint-disable-next-line
            console.log(res);
            if (res.meta.requestStatus === "fulfilled") {
                setTimeout(() => {
                    try {
                        window.close();
                        window.location.href = `${
                            process.env.REACT_APP_AUTH_APP_URL + "/home#/engagement/webPresence" ||
                            "https://app-dev.careexpand.com/home#/engagement/webPresence"
                        }`;
                    } catch (error) {
                        //eslint-disable-next-line
                        console.log(error);
                    }
                    setIsProcessing(false);
                }, 100);
            } else {
                setIsProcessing(false);
            }
        });
    };

    return (
        <div className="loginBodyContainer">
            <div className="carexLogoContainer0">
                <img className="carexLogo" src={carexLogo} alt="Careexpand_logo" />
                <div className="carexLogoText">The Operating System for Virtual Medicine</div>
            </div>
            <div className="leftSideDiv">
                <AuthBody />
            </div>
            <div className="rightSideDiv">
                <div className="connectYourAccounts" style={{ minWidth: "60%" }}>
                    <h4 className="accountsConnectionTitle">Connect your Accounts</h4>
                    <div
                        onClick={() => {
                            linkedInLogin();
                        }}
                    >
                        <img
                            onClick={() => linkedInLogin()}
                            src={linkedin}
                            alt="Sign in with Linked In"
                            style={{ width: "200px", maxWidth: "200px", cursor: "pointer" }}
                        />
                    </div>
                    <div className="BacktoMainActions">
                        <button
                            type="submit"
                            className="signUpButton"
                            style={{
                                textTransform: "unset",
                                fontSize:
                                    window.innerWidth <= 320
                                        ? "11px"
                                        : window.innerWidth === 768
                                        ? "10px"
                                        : window.innerWidth >= 426 && window.innerWidth <= 514
                                        ? "10px"
                                        : "14px",
                            }}
                            onClick={() => {
                                try {
                                    window.location.href = `${
                                        process.env.REACT_APP_HOME_APP_URL + "/home#/engagement/webPresence" ||
                                        "https://app-dev.careexpand.com/home#/engagement/webPresence"
                                    }`;
                                } catch (error) {
                                    //eslint-disable-next-line
                                    console.log(error);
                                }
                            }}
                        >
                            Back to accounts
                        </button>
                    </div>
                    {isProcessing && (
                        <div
                            className={"simplePostEditorLoadingModal"}
                            onClick={(e: any) => {
                                e.stopPropagation();
                            }}
                        >
                            <div
                                style={{
                                    width: "max-content",
                                    height: "max-content",
                                    backgroundColor: "#fff",
                                    borderRadius: 3,
                                    padding: "10px",
                                }}
                            >
                                <Spiner />
                                <div style={{ margin: 10, textAlign: "center" }} className="postsEditorSectionTitle">
                                    {isProcessing === true
                                        ? "Please wait, we are writing your post..."
                                        : `Please wait, ${isProcessing}`}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
export default ConnectYourAccounts;
