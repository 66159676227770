import React, { useState, useRef } from "react";
import Loader from "../../assets/images/Preloader_7.gif";
import validationServices from "../../api/validation.api";
import { httpErrorHandler } from "./utils";

type ValidationArticleProps = {
    numberOfDigit: number;
    id: string | null;
    signature: string | null;
};

const isNumeric = /^[0-9]+$/;

const ValidationForm = ({ numberOfDigit, id, signature }: ValidationArticleProps) => {
    const [otp, setOtp] = useState(new Array(numberOfDigit).fill(""));
    const [loading, setLoading] = useState(false);
    const [otpError, setOtpError] = useState("");
    const [verified, setVerified] = useState(false);
    const otpBoxRef = useRef<(HTMLInputElement | null)[]>(new Array(numberOfDigit).fill(null));

    const HandleValidation = async () => {
        const otpValue = otp.join("");
        setOtpError("");

        if (otpValue.length > 0 && !isNumeric.test(otpValue)) {
            setOtpError("OTP must be numbers");
            return;
        }

        if (otpValue.length === numberOfDigit) {
            setLoading(true);
            setOtpError("");
            try {
                const resp = await validationServices.postOTPAndDownloadCCDA({
                    id,
                    signature,
                    otp: otpValue,
                });

                setLoading(false);
                setVerified(true);

                // Create a Blob from the response data
                const blob = new Blob([resp], { type: "application/xml" });

                // Create a link element
                const link = document.createElement("a");

                // Set the href attribute with the Blob object
                link.href = window.URL.createObjectURL(blob);

                // Set the download attribute with the desired file name
                link.download = `${new Date().getTime()}ccda.xml`;

                // Append the link to the body
                document.body.appendChild(link);

                // Trigger a click on the link to start the download
                link.click();

                // Remove the link from the body
                document.body.removeChild(link);
            } catch (error) {
                httpErrorHandler(error, undefined, setOtpError);
            } finally {
                setLoading(false);
            }
        } else {
            setOtpError("Please enter a valid 6-digit OTP.");
        }
    };

    const handleChange = (value: string, index: number): void => {
        const newArr = [...otp];
        newArr[index] = value;
        setOtp(newArr);
        if (value.length > 0) {
            otpBoxRef.current[index + 1]?.focus();
        }
    };

    const handleBackSpaceAndEnter = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key === "Backspace" && index > 0) {
            otpBoxRef.current[index - 1]?.focus();
        }
        if (e.key === "Enter" && e.currentTarget.value) {
            otpBoxRef.current[index + 1]?.focus();
        }
    };
    return verified ? (
        <article className="validationContainer">
            <div className="validationHeader">
                <h4 className="validationTitle">
                    <span className="shield">&#10003;</span>Verified
                </h4>
                <p>Your file will be downloaded automatically</p>
            </div>
        </article>
    ) : (
        <div className="validationContainer">
            <div className="validationHeader">
                <h4 className="validationTitle">Please Verify OTP</h4>
                <p>Enter the six digit code we sent to your email address to download the file</p>
            </div>
            <article className="validationWrapper">
                {otp.map((digit: string, index: number) => (
                    <input
                        value={digit}
                        key={index}
                        className="validationInput"
                        maxLength={1}
                        onChange={e => handleChange(e.target.value, index)}
                        onKeyUp={e => handleBackSpaceAndEnter(e, index)}
                        ref={reference => ((otpBoxRef?.current as (HTMLInputElement | null)[])[index] = reference)}
                    />
                ))}
            </article>
            <button className="validationBtn" onClick={HandleValidation}>
                {loading ? <img src={Loader} alt="loading icon" style={{ width: "2rem" }} /> : "Verify"}
            </button>
            <div>{otpError && <small className="textError">{otpError}</small>}</div>
        </div>
    );
};
export default ValidationForm;
