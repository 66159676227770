import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import "./ResetPasswordForm.styles.sass";
import { ConsoleLogger } from "../../../utils/logger";
const logger = new ConsoleLogger();
import { postResetPasswordThunkAction } from "../../../redux/auth/auth.slice";
import PasswordStrengthChecker from "../../../components/common/PasswordStrengthChecker/PasswordStrengthChecker";
import { useAppDispatch } from "../../../redux/hooks";
import Spiner from "../../../components/common/Spiner/Spiner";
import { CEXModal } from "@careexpand/cex-core-components/esm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from '@fortawesome/pro-light-svg-icons';

const ResetPasswordForm = () => {
    const [status, setStatus] = useState<string>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>();
    const dispatch = useAppDispatch();
    const [t]: any = useTranslation("global");
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isRepeatPasswordVisible, setIsRepeatPasswordVisible] = useState(false);
    const [passwordInput, setPasswordInput] = useState();
    const [passwordStrength, setPasswordStrength] = useState(0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [searchParams, _] = useSearchParams();
    const [modalValue, setModalValue] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const checkPasswordStrength = (password: any) => {
        const strongPassword = new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})");
        const mediumPassword = new RegExp(
            "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))"
        );
        if (password === "") setPasswordStrength(0);
        if (strongPassword.test(password)) {
            setPasswordStrength(3);
        } else if (mediumPassword.test(password)) {
            setPasswordStrength(2);
        } else {
            setPasswordStrength(1);
        }
    };

    const closeModal = () => {
        if (status === "error") {
            setModalValue("")
        } else {

            window.location.assign("/");
        }
    };

    return (
        <div className="userDetailsForm">
            {modalValue && modalValue.length && <CEXModal
                type="info"
                handleModal={() => closeModal()}
                title={"Attention"}
                subtitle={modalValue}
            />}
            {isLoading && <Spiner />}

            <Formik
                initialValues={{ password: "", repeatpassword: "" }}
                onSubmit={(values, actions) => {
                    setIsLoading(true);
                    setIsSubmitting(true);
                    if (values.repeatpassword === "" || values.password === "") {
                        actions.setStatus("hola");
                        setStatus("error");
                    } else {
                        setIsSubmitting(false);
                        dispatch(
                            postResetPasswordThunkAction({
                                data: {
                                    token: searchParams.get("token") || "",
                                    password: values.password,
                                },
                            })
                        )
                            .then((res: any) => {
                                if (res.error) {
                                    setModalValue("Error updating password");
                                    logger.log("Error updating password");
                                } else {
                                    setModalValue(res.payload);
                                }
                            })
                            .finally(() => setIsLoading(false));
                    }
                }}
                validationSchema={yup.object().shape({
                    password: yup
                        .string()
                        .required("Please, fill with your last name")
                        .min(8, "Please, fill with your last name"),
                    repeatpassword: yup
                        .string()
                        .required("Passwords must match")
                        .oneOf([yup.ref("password"), null], "Passwords must match"),
                })}
            >
                {({ errors, handleBlur }: any) => (
                    <Form className="userDetailsForm">
                        <div className="inputFieldDiv">
                            <div className="pi-input-skel-block">
                                <div className="pi-input">
                                    <label className="pi-label" htmlFor="">{t("register.password")}</label>
                                    <div className="pi-input-skel">
                                        <span className="pi-input-prefix" style={{ padding: '8px 8px', cursor: 'pointer' }} onClick={() => setIsPasswordVisible(!isPasswordVisible)}>
                                            <FontAwesomeIcon icon={isPasswordVisible ? faEye : faEyeSlash} size="2xl" style={{ width: '17px' }} />
                                        </span>
                                        <Field
                                            name="password"
                                            type={isPasswordVisible ? "text" : "password"}
                                            placeholder={t("register.password-label")}
                                            className="pi-input-ele"
                                            validate={(e: any) => {
                                                setPasswordInput(e);
                                                checkPasswordStrength(passwordInput);
                                            }}
                                            onBlur={handleBlur}
                                        />
                                        <span className="tooltiptext">
                                            You must include at least a capital and small letter, a number an a special character.
                                        </span>
                                    </div>
                                </div>
                                <label className="input__error">{errors.touched && errors.password}</label>
                            </div>
                            <div className="pi-input-skel-block" style={{ marginTop: '20px' }}>
                                <div className="pi-input">
                                    <label className="pi-label" htmlFor="">{t("register.repeat-password")}</label>
                                    <div className="pi-input-skel">
                                        <span className="pi-input-prefix" style={{ padding: '8px 8px', cursor: 'pointer' }} onClick={() => setIsRepeatPasswordVisible(!isRepeatPasswordVisible)}>
                                            <FontAwesomeIcon icon={isRepeatPasswordVisible ? faEye : faEyeSlash} size="2xl" style={{ width: '17px' }} />
                                        </span>
                                        <Field
                                            name="repeatpassword"
                                            type={isRepeatPasswordVisible ? "text" : "password"}
                                            placeholder={t("register.repeat-password-label")}
                                            className="pi-input-ele"
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                </div>
                                <label className="input__error">{errors.repeatpassword && errors.repeatpassword}</label>
                            </div>
                        </div>
                        <PasswordStrengthChecker passwordStrength={passwordStrength} />
                        <div className="reset_btn_container">
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className="reset_blueButton"
                                style={{
                                    fontSize:
                                        window.innerWidth <= 320
                                            ? "11px"
                                            : window.innerWidth === 768
                                                ? "10px"
                                                : window.innerWidth >= 426 && window.innerWidth <= 514
                                                    ? "10px"
                                                    : "14px",
                                }}
                            >
                                Reset Password
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ResetPasswordForm;
