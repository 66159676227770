import { doGet, doPatch, doPost } from "./gateway/carexGateway";
import { CancelToken } from "axios";
import { ICreatePost, IGetPosts, IGetAIPost, IPostAccount, ICreateReview } from "../redux/posts/posts.types";

const postsFeedService = {
    addNewPost: async (payload: ICreatePost, cancelToken?: CancelToken) => {
        try {
            const data = { ...payload };

            const res = await doPost<ICreatePost, any>("webPresencePosts", {
                data,
                cancelToken,
            });
            return Promise.resolve(res.data);
        } catch (err) {
            return Promise.reject(err);
        }
    },
    addNewReview: async (payload: ICreateReview, cancelToken?: CancelToken) => {
        try {
            const data = { ...payload };

            const res = await doPost<ICreateReview, any>("webPresenceReviews", {
                data,
                cancelToken,
            });
            return Promise.resolve(res.data);
        } catch (err) {
            return Promise.reject(err);
        }
    },
    getAllPosts: async (payload: IGetPosts, cancelToken?: CancelToken) => {
        try {
            const data = { ...payload };

            const res = await doGet<any>("webPresenceGetPosts", {
                params: { ":offset": payload.offset.toString(), ":limit": payload.limit.toString() },
                data,
                cancelToken,
            });
            return Promise.resolve(res.data);
        } catch (err) {
            return Promise.reject(err);
        }
    },
    updatePost: async (idPost: number, payload: ICreatePost, cancelToken?: CancelToken) => {
        try {
            const data = { ...payload };

            const res = await doPatch<any, any>("updateWebPresencePosts", {
                params: { ":idPost": idPost.toString() },
                data,
                cancelToken,
            });
            return Promise.resolve(res.data);
        } catch (err) {
            return Promise.reject(err);
        }
    },

    getAIPostData: async (payload: IGetAIPost, cancelToken?: CancelToken) => {
        try {
            const data = { ...payload };

            const res = await doPost<any, any>("webPresenceAIPosts", {
                data,
                cancelToken,
            });
            return Promise.resolve(res.data);
        } catch (err) {
            return Promise.reject(err);
        }
    },
    createPostAccount: async (payload: IPostAccount, cancelToken?: CancelToken) => {
        try {
            const data = { ...payload };

            const res = await doPost<any, any>("webPresencePostAccounts", {
                data,
                cancelToken,
            });
            return Promise.resolve(res.data);
        } catch (err) {
            return Promise.reject(err);
        }
    },
    updatePostAccount: async (payload: IPostAccount, cancelToken?: CancelToken) => {
        try {
            const data = { ...payload };

            const res = await doPatch<any, any>("webPresencePostAccounts", {
                data,
                cancelToken,
            });
            return Promise.resolve(res.data);
        } catch (err) {
            return Promise.reject(err);
        }
    },
    getAllPostAccounts: async (payload: any, cancelToken?: CancelToken) => {
        try {
            const data = { ...payload };

            const res = await doGet<any>("webPresencePostAccounts", {
                params: {},
                data,
                cancelToken,
            });
            return Promise.resolve(res.data);
        } catch (err) {
            return Promise.reject(err);
        }
    },
};

export default postsFeedService;
