import { combineReducers } from "@reduxjs/toolkit";
import authSliceReducer from "./auth/auth.slice";
import postsSliceReducer from "./posts/posts.slice";
import validationSliceReducer from "./validation/validation.slice";
import thirdPartyAuthReducer from "./thirdPArtyAuth/thirdPartyAuth.slice";
import patientsLisReducer from "./patientsList/patientsList.slice";

const rootReducer = combineReducers({
    authReducer: authSliceReducer,
    postsReducer: postsSliceReducer,
    validationReducer: validationSliceReducer,
    thirdPartyAuthReducer: thirdPartyAuthReducer,
    patientsListReducer: patientsLisReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
