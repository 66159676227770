import React from "react";
import "./Spiner.styles.sass";
export const Spiner: React.FC = () => {
    return (
        <div
            className="loading__spinner"
            
        >
            <div className="loading__image-spinner"></div>
        </div>
    );
};
export default Spiner;
