import { RootState } from "../rootReducer";

export const ThirdPartyAuthTokenDataSelector = (state: RootState) => state.thirdPartyAuthReducer.thirdPartyAuthToken;

export const isLoadingThirdPartyAuthTokenDataSelector = (state: RootState) =>
    state.thirdPartyAuthReducer.isLoadingThirdPartyAuthToken;

export const isGettingTokenFromAuth0Selector = (state: RootState) =>
    state.thirdPartyAuthReducer.isGettingTokenFromAuth0;
export const tokenFromAuth0DataSelector = (state: RootState) => state.thirdPartyAuthReducer.tokenFromAuth0Data;
export const isCreatingThirdPartyAuthTokenSelector = (state: RootState) =>
    state.thirdPartyAuthReducer.isCreatingThirdPartyAuthToken;
export const creationSuccessfulSelector = (state: RootState) => state.thirdPartyAuthReducer.creationSuccessful;
export const createdTokenDataSelector = (state: RootState) => state.thirdPartyAuthReducer.createdTokenData;
